import { memo } from "react";

import BB_Button from "@/Components/BB_Button";
import BB_Meta from "@/Components/BB_Meta";
import ChevronLeftIcon from "@/Icons/chevron_left.svg?react";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import GuestLayout from "@/Layouts/GuestLayout";
import BB_User from "@/types/BB_User";

export type ErrorPageProps = {
	auth?: { user?: BB_User | null };
	statusCode: number;
};

type Dict = { [key: number]: string };

const titleDict: Dict = {
	503: "503: Dienst nicht verfügbar",
	500: "500: Serverfehler",
	404: "404: Seite nicht gefunden",
	403: "403: Zugriff verweigert"
};

const descriptionDict: Dict = {
	503: "Entschuldigung, wir führen gerade Wartungsarbeiten durch. Bitte versuche es später erneut.",
	500: "Ups, auf unserer Seite ist etwas schiefgelaufen. Unsere Techniker sind informiert.",
	404: "Entschuldigung, die gesuchte Seite konnte nicht gefunden werden.",
	403: "Entschuldigung, du hast keine Berechtigung, auf diese Seite zuzugreifen."
};

const ErrorPage = (props: ErrorPageProps) => {
	const Layout = props.auth?.user !== undefined ? AuthenticatedLayout : GuestLayout;

	const title = titleDict[props.statusCode];
	const description = descriptionDict[props.statusCode];

	return (
		<Layout hideButtons={props.auth?.user === undefined}>
			<BB_Meta
				title={title + " - Bestell.bar"}
				description="Ein Fehler ist aufgetreten. Bitte versuche es später erneut."
				keywords={props.statusCode + ", Fehler, Bestell.bar"}
			/>

			<div className={"pt-4 sm:mx-auto sm:w-full sm:max-w-[480px]"}>
				<h2 className="mb-4 ps-3 text-left text-xl font-bold sm:ps-2">{title}</h2>
				<div className="bg-background-100 dark:bg-dark-background-300 rounded-none px-3 py-6 shadow-lg sm:rounded-lg sm:px-6">
					<p>{description}</p>

					<br />

					<BB_Button
						type="primary"
						title={"Zurück zur Startseite"}
						icon={ChevronLeftIcon}
						iconClassName="h-5 w-5 mr-2 pointer-events-none"
						buttonClassName={"sm:w-fit sm:px-8"}
						onClick={route("home")}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default memo(ErrorPage);
